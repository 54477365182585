.button-style {
	/* 
    1rem is the size of the spinner-width-sm in bootstrap 4
    https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss#L1078
  */
	left: calc(50% - calc(1rem / 2));
}

.span-style {
	/* 
    we have to offset the inner child or else 
    the spinner width will offset the center
    of the child text 
  */
	margin-right: 1rem;
}

.select-muti > div {
	min-height: 114px;
}
.custom-file-input-css::-webkit-file-upload-button {
	visibility: hidden;
}
.custom-file-input-css::before {
	content: "Chọn file";
	display: inline-block;
	background: linear-gradient(top, #f9f9f9, #e3e3e3);
	border: 1px solid #999;
	border-radius: 3px;
	padding: 5px 8px;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	text-shadow: 1px 1px #fff;
	font-weight: 700;
	font-size: 10pt;
}
.custom-file-input-css:hover::before {
	border-color: black;
}
.custom-file-input-css:active::before {
	background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.table td {
	white-space: initial;
	padding-left: 10px !important;
	padding-right: 10px !important;
	/* text-align: center; */
}
.table th {
	padding-left: 10px !important;
	padding-right: 10px !important;
	/* text-align: center; */
	background-color: #fff !important;
}
/* .select-custom > div {
  background-color: #5e72e4 !important;
}

.select-custom > div > div > div {
  color: #fff;
} */
td {
	vertical-align: middle !important;
}

.nplError > div {
	border: 1px solid #fb6340;
}

.btn-none {
	border: none;
	background-color: transparent;
	padding: 0;
	margin-right: 0.5rem;
}

.border-bottom-search {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
	background-color: white !important;
	box-shadow: none !important;
}

.border-bottom-tab-active {
	border-bottom: 2px solid #00bbf1;
}

.btn-nav-none {
	border: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10;

}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	width: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
	width: 5px;
}

table {
	text-align: left;
	position: relative;
	border-collapse: collapse;
}
th {
	position: sticky;
	top: 0; /* Don't forget this, required for the stickiness */
	box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.max-height-input-custom {
	max-height: 36px;
}

.transition-filter {
	transition: transform 0.3s ease-out;
}

.text-muted {
	color: #333 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	color: #000000;
}
.form-control-label {
	color: #000000;
}
.form-control:focus {
	color: #000000;
}

.form-control {
	color: #000000;
}

.react-bootstrap-table th {
	color: #000000;
}

body {
	color: #000000;
}

.table th,
.table td {
	color: #000000 !important;
}

.rdp-addon {
	display: none;
}

@media print {
	@page {
		size: 80mm 40mm;
	}
}
.required {
	position: relative;
}
.required::after {
	content: "*";
	position: absolute;
	top: 0;
	left: 100%;
	color: red;
}

.react-bootstrap-table {
	overflow: auto;
	max-height: 550px;
}